import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import Header from "../../components/Header";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Video from "../../components/Video";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Heading1 = ({ children }) => (
  <div>
    <h1 className="mb-8 text-center font-sans text-base font-medium uppercase tracking-wide text-ag-pink1">
      {children}
    </h1>
  </div>
);
const Heading2 = ({ children }) => (
  <div>
    <h2 className="mb-8 block text-center font-sans text-3xl font-medium leading-8 tracking-tight text-gray-700 sm:text-4xl">
      {children}
    </h2>
  </div>
);
const Heading3 = ({ children }) => (
  <div className="prose-lg mx-auto mb-8 max-w-prose">
    <h2 className="font-sans">{children}</h2>
  </div>
);
const Heading4 = ({ children }) => (
  <div className="mx-auto mb-8 max-w-prose text-lg">
    <h4 className="text-xl leading-8 text-gray-500">{children}</h4>
  </div>
);

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
    [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <div className="prose prose-lg prose-pink mx-auto mb-8 text-gray-500">
        {children}
      </div>
    ),
  },
};

function extractPropChildren(reactElement, childrenArray, hasLi) {
  for (const [index, child] of reactElement.props.children.entries()) {
    if (child.type === "div") {
      if (hasLi) {
        childrenArray.push(
          <li key={index + childrenArray.length}>{child.props.children}</li>
        );
      } else {
        childrenArray.push(
          <p key={index + childrenArray.length}>{child.props.children}</p>
        );
      }
      continue;
    } else {
      extractPropChildren(child, childrenArray, hasLi);
    }
  }
  return childrenArray;
}

function filterRichText(text) {
  const renderedText = renderRichText(text, options);
  for (let [index, elem] of renderedText.entries()) {
    if ("ul" === elem.type) {
      renderedText.splice(
        index,
        1,
        <div key={index} className="prose prose-lg prose-pink mx-auto mb-8">
          <ul>{extractPropChildren(elem, [], true)}</ul>
        </div>
      );
      continue;
    }
    if ("ol" === elem.type) {
      renderedText.splice(
        index,
        1,
        <div key={index} className="prose prose-lg prose-pink mx-auto mb-8">
          <ol>{extractPropChildren(elem, [], true)}</ol>
        </div>
      );
      continue;
    }
    if ("blockquote" === elem.type) {
      renderedText.splice(
        index,
        1,
        <div key={index} className="prose prose-lg prose-pink mx-auto mb-8">
          <blockquote>{extractPropChildren(elem, [], false)}</blockquote>
        </div>
      );
      continue;
    }
  }
  return renderedText;
}

function DropdownDisclosure({ title, content }) {
  return (
    <Disclosure as="div" key={title} className="">
      {({ open }) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
              <span className="font-medium text-gray-900">{title}</span>
              <span className="ml-6 flex h-7 items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            {content}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default function ContentPage({
  data: { page, contentfulNavigationBar, contentfulFooter },
}) {
  return (
    <Layout footerElements={contentfulFooter}>
      <Header
        heading={page.heading}
        subheading={page.subheading}
        buttonList={false}
        image={page.headerImage}
        readingDuration={page.durationInMinutes}
        navBar={contentfulNavigationBar.navigationBarList}
      />
      <div className="mt-12 overflow-hidden bg-white p-8 font-serif">
        {page.content.map((element, index) => {
          if ("text" in element) {
            return filterRichText(element.text);
          } else if ("image" in element) {
            let imageSubheading = false;
            if (element.imageSubheading) {
              imageSubheading = renderRichText(element.imageSubheading);
            }
            let objectFit = "";
            if (element.imageFit === "Contain") {
              objectFit = "contain";
            }

            return (
              <figure key={index} className="mx-auto mb-8 w-full max-w-4xl">
                <GatsbyImage
                  className="w-full"
                  objectFit={objectFit}
                  image={getImage(element.image)}
                  alt=""
                />
                {imageSubheading && (
                  <div className="prose prose-sm prose-pink mx-auto text-gray-500">
                    {imageSubheading}
                  </div>
                )}
              </figure>
            );
          } else if ("dropdownContent" in element) {
            const dropdownContent = filterRichText(element.dropdownContent);
            const dropdownHeading = element.title;
            return (
              <div
                key={element.title}
                className="prose-lg mx-auto mb-8 max-w-prose"
              >
                <DropdownDisclosure
                  title={dropdownHeading}
                  content={dropdownContent}
                />
              </div>
            );
          } else if ("spacer" in element) {
            if (element.spacer === "Normal") {
              return <div key={element.spacer} className="h-8 w-full"></div>;
            }
            if (element.spacer === "Double") {
              return <div key={element.spacer} className="h-16 w-full"></div>;
            }
          } else if ("videoCode" in element) {
            if (
              element.videoCode === "china" ||
              element.videoCode === "lactoferrin"
            ) {
              return (
                <div key={index} className="mx-auto mb-8 w-full max-w-4xl">
                  <div className="!aspect-w-16 !aspect-h-9">
                    <Video videoCode={element.videoCode} />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index} className="mx-auto mb-8 w-full max-w-4xl">
                  <div className="!aspect-w-16 !aspect-h-9">
                    <iframe
                      title={element.videoCode}
                      src={`https://www.youtube.com/embed/${element.videoCode}`}
                      className="h-full w-full"
                      frameBorder={0}
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              );
            }
          }
          return <></>;
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    page: contentfulContentPage(
      slug: { eq: $slug }
      node_locale: { eq: "de" }
    ) {
      heading
      subheading
      headerImage {
        gatsbyImageData
      }
      durationInMinutes
      content {
        ... on ContentfulContentImage {
          image {
            gatsbyImageData
          }
          imageSubheading {
            raw
          }
          imageFit
        }
        ... on ContentfulContentDropdown {
          title
          dropdownContent {
            raw
          }
        }
        ... on ContentfulContentText {
          text {
            raw
          }
        }
        ... on ContentfulContentSpacer {
          spacer
        }
        ... on ContentfulContentVideo {
          videoCode
        }
      }
    }
    contentfulNavigationBar(node_locale: { eq: "de" }) {
      navigationBarList {
        linksToSlug
        title
      }
    }
    contentfulFooter(node_locale: { eq: "de" }) {
      footerElements {
        linksToSlug
        title
      }
    }
  }
`;
